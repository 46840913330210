import { Container, Row, Col, Alert } from 'react-bootstrap';

const DeliveryAPIError = ({ data }) => {
  return (
    <Container data-testid="delivery-api-error">
      <Row>
        <Col>
          <Alert variant="danger">
            <h4>
              Error {data.status}: {data.statusText}
            </h4>
            <p>
              {data.error_message} <strong>(Error Code {data.error_code})</strong>
            </p>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default DeliveryAPIError;
